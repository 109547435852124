/* Fonts */
@font-face {
    font-family: 'Glober';
    font-weight: 400; 
    src: url('../fonts/globerregular-webfont.eot');
    src: url('../fonts/globerregular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/globerregular-webfont.woff2') format('woff2'), 
    url('../fonts/globerregular-webfont.woff') format('woff'),
    url('../fonts/globerregular-webfont.ttf') format('truetype');
}
@font-face {
    font-family: 'Glober';
    font-weight: 500; 
    src: url('../fonts/globersemibold-webfont.eot');
    src: url('../fonts/globersemibold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/globersemibold-webfont.woff2') format('woff2'), 
    url('../fonts/globersemibold-webfont.woff') format('woff'),
    url('../fonts/globersemibold-webfont.ttf') format('truetype');
}
@font-face {
    font-family: 'Glober';
    font-weight: 600; 
    src: url('../fonts/globerbold-webfont.eot');
    src: url('../fonts/globerbold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/globerbold-webfont.woff2') format('woff2'), 
    url('../fonts/globerbold-webfont.woff') format('woff'),
    url('../fonts/globerbold-webfont.ttf') format('truetype');
}
@font-face {
    font-family: 'fontello';
    src: url('../fonts/fontello.eot?70745582');
    src: url('../fonts/fontello.eot?70745582#iefix') format('embedded-opentype'),
         url('../fonts/fontello.woff?70745582') format('woff'),
         url('../fonts/fontello.ttf?70745582') format('truetype'),
         url('../fonts/fontello.svg?70745582#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}


[class^="i-"]:before, [class*=" i-"]:before,
.i-rub:after {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    z-index: -1 !important;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */
   
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
   
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
   
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;
   
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
   
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
   
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  }
 
  .i-rub{
      white-space: nowrap;
  }
 


.i-arrow:before { content: '\e800'; } /* '' */
.i-arrow-b:before { content: '\e801'; } /* '' */
.i-attantion:before { content: '\e802'; } /* '' */
.i-cart:before { content: '\e803'; } /* '' */
.i-check:before { content: '\e804'; } /* '' */
.i-close:before { content: '\e805'; } /* '' */
.i-compare:before { content: '\e806'; } /* '' */
.i-favorite:before { content: '\e808'; } /* '' */
.i-profile:before { content: '\e809'; } /* '' */
.i-reviews:before { content: '\e80a'; } /* '' */
// .i-rub:after { content: '\e80e'; } 
.i-rub:after { content: '₽'; padding-left: .25em; width: auto !important; font-family: sans-serif;} /* '' */
.i-end:before { content: '\e80d'; } /* '' */
.i-home:before { content: '\e80c'; } /* '' */
.i-phone:before { content: '\e80b'; } /* '' */
.i-mobile:before { content: '\e807'; } /* '' */
.i-calendar:before { content: '\e80f'; } /* '' */
.i-map:before { content: '\e811'; } /* '' */
.i-opening:before { content: '\e810'; } /* '' */
.i-square:before { content: '\e813'; } /* '' */
.i-fax:before { content: '\e814'; } /* '' */
.i-mail:before { content: '\e817'; } /* '' */
.i-remove:before { content: '\e816'; } /* '' */
   

/* Colors */
$clr_white: #fff;
$clr_black: #000;
$clr_gray: #999;
$clr_dark: #2B2B2B;
$clr_blue: #0A1A5C;
$clr_blueLighten: #3A50AE;
$clr_primary: #bf2362;
$clr_orange: #F26522;
//$clr_red: #FB3F4C; 
$clr_red: #f84147; 
$clr_red-darken: #CF000E; 
$clr_green: #008768;

$transition: .2s;

.red{
    color: $clr_red !important;
    &-bg{
        background: $clr_red !important;
    }
}

.orange{
    color: $clr_orange !important;
    &-bg{
        background: $clr_orange;
    }
}

.green{
    color: $clr_green !important;
    &-bg{
        background: $clr_green !important;
    }
}

.primary{
    color: $clr_primary !important;
    &-bg{
        background: $clr_primary !important;
    }
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    border-color: inherit;
    background: inherit;
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus{

}
.btn-primary{
    background-color: $clr_primary;    
    background: linear-gradient(160deg,$clr_primary 0, darken($clr_primary, 10%) 100%);
    border-color: $clr_primary;
    transition: $transition;
    &:hover {
        color: #fff;
        background-color: lighten($clr_primary, 5%);
        background: linear-gradient(160deg, lighten($clr_primary, 5%) 0, darken($clr_primary, 0%) 100%);
        background: linear-gradient(160deg,$clr_primary 0, darken($clr_primary, 10%) 100%);
        filter: brightness(1.1);
        border-color: $clr_primary;
    }
    &:focus {
        color: #fff;
        background-color: darken($clr_primary, 5%);
        border-color: $clr_primary;
        box-shadow: 0 0 0 0.1rem rgba(191, 35, 98, .4);
    }
    &:not(:disabled):not(.disabled).active, 
    &:not(:disabled):not(.disabled):active{
        color: #fff;
        background-color: $clr_primary;
        border-color: $clr_primary;
    }
    &:not(:disabled):not(.disabled).active:focus, 
    &:not(:disabled):not(.disabled):active:focus {
        box-shadow: 0 0 0 0.1rem rgba(191, 35, 98, .4);
    }
}
.btn-outline-primary{  
    border-color: $clr_primary;
    transition: $transition;
    color: $clr_primary;
    &:hover {
        color: #fff;        
        background: $clr_primary;
        border-color: $clr_primary;
    }
    &:focus {
        color: #fff;
        background-color: darken($clr_primary, 5%);
        border-color: $clr_primary;
        box-shadow: 0 0 0 0.1rem rgba(191, 35, 98, .4);
        filter: brightness(1.1);
    }
    &:not(:disabled):not(.disabled).active, 
    &:not(:disabled):not(.disabled):active{
        color: #fff;
        background-color: $clr_primary;
        border-color: $clr_primary;
    }
    &:not(:disabled):not(.disabled).active:focus, 
    &:not(:disabled):not(.disabled):active:focus {
        box-shadow: 0 0 0 0.1rem rgba(191, 35, 98, .4);
    }
}
button.btn{
    &.orange-bg{
        &:not(:disabled):not(.disabled):active:focus,
        &:focus, &:not(:disabled):not(.disabled):active:focus {
            background: $clr_orange;
            box-shadow: 0 0 0 0.2rem rgba(243, 118, 58, 0.35);
        }
    }
    p{
        margin: 0;
    }
}

.form-control:focus{
    box-shadow: none;
    border-color: transparent;
}

@media (min-width: 1350px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1326px;
    }
}


svg{
    &.w20{
        width: 20px;
        height: 20px;
    }
}