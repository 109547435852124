.preloader {
    position: relative;
    display: flex;
    justify-content: center;

    .image-spin {
        position: absolute;
    }

    .image-blur {}
}